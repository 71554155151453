import { createGlobalStyle } from '@nfront/global-styles'
import normalize from '../styles/normalize'
import globalStyle from '../styles/globalStyle'

const GlobalStyleComponent = createGlobalStyle`
  ${normalize}
  ${globalStyle}
`

export default GlobalStyleComponent
