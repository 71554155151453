import { css } from '@nfront/global-styles'

const globalStyles = css `
  img {
    max-width: 100%;
  }

  html,
  body {
    background: #100f21;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    -webkit-overflow-scrolling: touch;
  }

  *:before,
  *:after,
  * {
    box-sizing: border-box;
  }
`

export default globalStyles